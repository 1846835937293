import React from 'react';

import InfoTooltip from '../../../InfoTooltip/InfoTooltip';
import Arrow from '../../../../assets/img/icons/ic-chevron-right-light-gray.svg';
import Slider from '../../../Slider';

import styles from '../SearchProfile.module.scss';

const AdjustInterestsInputs = ({
  inputs = [],
  max,
  sliderLabelClassName,
  sliderClassName,
}) => (
  <section className="pt-0">
    <div className="flex">
      <div className={styles.sectionSubtitle}>
        Level of Interest
      </div>
      <InfoTooltip>
        <div className={`flex ${styles.sectionTooltip}`}>
          <p className="mb-0">
            1 - None
          </p>
          <div className={styles.mx15}>
            <Arrow />
            <Arrow />
          </div>
          <p className="mb-0">
            4 - Focus
          </p>
        </div>
      </InfoTooltip>
    </div>
    {inputs.map(({ name, label }) => (
      <Slider
        key={name}
        max={max}
        labelClassName={sliderLabelClassName}
        wrapperClassName="row flex-center mb-28"
        sliderClassName={sliderClassName}
        label={label}
        name={name}
      />
    ))}
  </section>
);

export default AdjustInterestsInputs;
