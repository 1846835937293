import React from 'react';
import Slider from 'rc-slider';
import { useField } from 'formik';
import 'rc-slider/assets/index.css';

import styles from './index.module.scss';

export const sliderStyles = {
  railStyle: {
    background: '#80D4FF',
    border: 'none',
    borderRadius: '0',
    height: '1px',
    marginTop: '2px',
  },
  dotStyle: {
    height: '10px',
    width: '1px',
    background: '#80D4FF',
    borderRadius: '1px',
    bottom: '-3px',
    border: 'none',
    marginLeft: '0px',
  },
  activeDotStyle: {
    height: '15px',
    width: '5px',
    bottom: '-5px',
    borderRadius: '3px',
    background: '#02AAFF',
    border: 'none',
    marginLeft: '-2px',
  },
  handleStyle: {
    display: 'none',
    height: '9px',
    width: '1px',
  },
};

const Slider1 = ({
  label,
  name,
  min = 1,
  max = 5,
  step = 1,
  wrapperClassName = '',
  labelClassName = '',
  sliderClassName = '',
}) => {
  const [field, meta, { setValue, setTouched }] = useField(name);

  const createMarks = (dotsCount) => [...Array(dotsCount + 1).keys()];

  const handleChange = (value) => {
    setValue(value, true);
    setTouched(true);
  };

  return (
    <div className={wrapperClassName}>
      <div className={`${labelClassName} ${styles.label}`}>
        {label}
      </div>
      <div className={`${styles.slider} ${sliderClassName}`}>
        <Slider
          {...sliderStyles}
          {...meta}
          className="rc-slider-koralgo"
          onChange={handleChange}
          name={field.name}
          value={field.value}
          min={min}
          max={max}
          marks={createMarks(max)}
          step={step}
          included={false}
          dots
        />
      </div>
    </div>
  );
};

export default Slider1;
