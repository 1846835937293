import http from './http.service';

const UsersService = () => {
  const basePath = '/users/my';

  const getSearchProfile = () => http.get(`${basePath}/search-profile`);
  const updateSearchProfile = (values) => http.put(`${basePath}/search-profile`, values);

  return {
    getSearchProfile,
    updateSearchProfile,
  };
};

export default UsersService;
