import React from 'react';

import Slider from '../../../Slider';
import InfoTooltip from '../../../InfoTooltip/InfoTooltip';

const PrioritiesInputs = ({
  inputs,
  sliderLabelClassName,
  sliderClassName,
  hideHeader,
  tooltipField,
  title,
}) => (
  <section>
    {title}
    {inputs.map(({ name, label }) => (
      <Slider
        labelClassName={sliderLabelClassName}
        wrapperClassName="row flex-center mb-28"
        sliderClassName={sliderClassName}
        key={name}
        label={hideHeader && (name === tooltipField)
          ? (
            <div className="flex">
              {label}
              <InfoTooltip>Relates to accommodation, if any</InfoTooltip>
            </div>
          )
          : label}
        name={name}
      />
    ))}
  </section>
);

export default PrioritiesInputs;
