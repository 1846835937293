import React from 'react';

import { Field } from 'formik';
import FormikRadioGroup from '../../../RadioGroup/RadioGroup';

import styles from '../SearchProfile.module.scss';

const AdjustInterestsAgeInput = ({
  radioLabelClassName,
  options,
}) => (
  <div>
    <div className={styles.sectionSubtitle}>
      Family, Adult or Senior Interests
    </div>
    <Field
      name="ageInterest"
      wrapperStyles={styles.radios}
      labelClassName={radioLabelClassName || styles.radiosLabel}
      options={options}
      component={FormikRadioGroup}
    />
  </div>
);

export default AdjustInterestsAgeInput;
