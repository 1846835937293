import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';

import StorageService from '../../../services/storage.service';
import Button from '../../UI/Button/Button';
import InfoTooltip from '../../InfoTooltip/InfoTooltip';
import AdjustInterestsInputs from './AdjustInterestsInputs/AdjustInterestsInputs';
import AdjustInterestsAgeInput from './AdjustInterestsAgeInput/AdjustInterestsAgeInput';
import PrioritiesInputs from './PrioritiesInputs/PrioritiesInputs';
import {
  activityRatingInterestInputs,
  activityRatingImportanceInputs,
  landingImportanceInputs,
  activityRatingAgeInterestOptions,
  activitySearchProfileInitialValues,
} from '../../../constans/acitivity-rating-profile';
import { LOCALSTORAGE_KEYS } from '../../../constans/localstorage';
import Arrow from '../../../assets/img/icons/ic-chevron-right-light-gray.svg';

import styles from './SearchProfile.module.scss';

export const ImportanceTitle = () => (
  <div className="flex">
    <div className={styles.sectionSubtitle}>
      Level of Importance
    </div>
    <InfoTooltip tooltipTextClassName={styles.ttip}>
      <div className={`flex flex-center ${styles.sectionTooltip}`}>
        <p className="mb-0">
          1 - Not important at all
        </p>
        <div className={`flex flex-center ${styles.mx15}`}>
          <Arrow />
          <Arrow />
          <Arrow />
        </div>
        <p className="mb-0">
          5 - Very important
        </p>
      </div>
    </InfoTooltip>
  </div>
);

const storageService = StorageService();

const SearchProfile = ({
  isLanding,
  onSubmit,
  tooltipField,
  disableSumbit,
  radioLabelClassName = '',
  buttonText = 'Save',
  canDisableButton = true,
  hideHeader = false,
  sliderLabelClassName = 'col-5',
  sliderClassName = 'col-7',
  buttonWrapperClassName = 'col-md-12 col-lg-6 ml-auto',
}) => {
  const profile = useMemo(() => storageService.get(LOCALSTORAGE_KEYS.USER), []);
  const { searchProfile } = profile;

  const activityImportanceInputs = isLanding
    ? landingImportanceInputs
    : activityRatingImportanceInputs;

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{ ...activitySearchProfileInitialValues, ...searchProfile }}
    >
      {({ dirty }) => (
        <Form>
          {!hideHeader && (
            <div className="flex flex-v-center property-edit-title property-edit-title__with-button">
              <span className={styles.sectionTitle}>
                Travel profile
              </span>
            </div>
          )}
          <AdjustInterestsInputs
            inputs={activityRatingInterestInputs}
            max={4}
            sliderClassName={sliderClassName}
            sliderLabelClassName={sliderLabelClassName}
          />
          <AdjustInterestsAgeInput
            radioLabelClassName={radioLabelClassName}
            options={activityRatingAgeInterestOptions}
          />
          <PrioritiesInputs
            title={<ImportanceTitle />}
            inputs={activityImportanceInputs}
            sliderLabelClassName={sliderLabelClassName}
            sliderClassName={sliderClassName}
            hideHeader={hideHeader}
            tooltipField={tooltipField}
          />
          <div className="row">
            <div className={`${styles.submitWrapper} ${buttonWrapperClassName} flex`}>
              <Button
                disabled={(canDisableButton && !dirty) || disableSumbit}
                type="submit"
                classes={`${styles.submitBtn} btn btn-uppercased btn_common t-600`}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SearchProfile.defaultProps = {
  isRedirectToSearch: true,
  submitBtnStyles: '',
};

export default SearchProfile;
